<template>
  <div>
    <div class="row w-100 px-md-5 my-4">
      <div class="col-6 col-md-3 logo_wrp">
        <b-link class="logo-login ml-2">
          <b-img
              :src="imgLogo" alt="logo"
          />
        </b-link>
      </div>
    </div>
    <!-- Error page-->
    <div class="misc-wrapper d-flex flex-column">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ $t("Page Not Found") }} 🕵🏻‍♀️
          </h2>
          <p class="mb-2">
            Oops! 😖  {{ $t("The requested URL was not found on this server.") }}
          </p>

          <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{path:'/'}"
          >
            {{ $t("Back to Overview") }}
          </b-button>

          <!-- image -->
          <b-img
              fluid
              :src="downImg"
              alt="Error page"
          />
        </div>
      </div>
    </div>
    <!-- / Error page-->
  </div>


</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error-light.svg'),
      logoImg: require('@/assets/images/logo/intellyscale.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImge
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/intellyscale-negativ.svg')
        return this.logoImg
      }
      return this.logoImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.logo-login {
  width: 100% ;
  img{
    width: 160px;
  }

}
</style>
